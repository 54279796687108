import 'normalize.css/normalize.css';
import './printing.css';
import '@hero-design/react/es/styles/index.css';
import 'react-grid-layout/css/styles.css';
import 'react18-json-view/src/style.css';

import '../modules/spa/styles';
import { preloadIcon } from '@ehrocks/hero-theme';

preloadIcon();
