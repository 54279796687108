import { globalEnvsRegistry } from '@ehrocks/envs-registry';
import {
  isEnabledKPTheme,
  getFileServiceApiHost,
  getFeatureFlagApiHost,
} from 'eh-utils/getFrontendEnv';

globalEnvsRegistry.registerConfig(
  'fileServiceApiHost',
  getFileServiceApiHost()
);
globalEnvsRegistry.registerConfig(
  'defaultEnabledTheme',
  isEnabledKPTheme() ? 'keypay' : 'employment-hero'
);
globalEnvsRegistry.registerConfig(
  'featureFlagApiHost',
  getFeatureFlagApiHost()
);
