import { RuntimeEnvironmentVariable } from './env';

const ENV: RuntimeEnvironmentVariable = {
  REACT_APP_MAIN_APP_HOST: '',
  REACT_APP_AUTHORIZATION_SERVICE_HOST: '//authorization.ehrocks.com',
  REACT_APP_BING_MAPS_SERVICE_API_KEY:
    'Ajpgpie7g3dbglz8pyBDzJn1YCABGu1G9Vf200bdCodrfSoA0-AfQaecXvX55YaD',
  REACT_APP_SCAN_VIRUS_URL: 'https://scan.ehrocks.com/v1/scans',
  REACT_APP_ENABLED_SCANNING_VIRUS: 'true',
  REACT_APP_FEATURE_FLAG_API_HOST: '//api.ehrocks.com/feature-flag-api',
  REACT_APP_FEATURE_GUIDE_HOST: 'https://feature-guide-api.ehrocks.com',
  REACT_APP_KP_SYNC_SERVICE_HOST: 'https://keypay-sync-service.ehrocks.com',
  REACT_APP_NOTIFICATION_GATEWAY_HOST:
    '//api.ehrocks.com/notification-gateway-service',
  REACT_APP_MARKETPLACE_HOST: 'https://marketplace.ehrocks.com',
  REACT_APP_HEROPAY_HOST: 'https://heropay-service.ehrocks.com',
  REACT_APP_HERODOLLARS_HOST: '//herodollar-service.ehrocks.com',
  REACT_APP_INSTAPAY_MARKETING_HOST:
    'https://instapay-marketing-service.ehrocks.com',
  REACT_APP_URL_TRACKING_SERVICE_HOST: 'https://track.ehrocks.com',
  REACT_APP_MARKETPLACE_INSPECTLET_ID: '9121978',
  REACT_APP_EH_HR_INSPECTLET_ID: '312737013',
  REACT_APP_EH_PLAYGROUND_INSPECTLET_ID: '',
  REACT_APP_EH_CMS_INSPECTLET_ID: '210567048',
  REACT_APP_CMS_PDF_EDITOR_INSPECTLET_ID: '1978374168',
  REACT_APP_EH_B2B_INSPECTLET_ID: '9121978',
  REACT_APP_ASSET_REGISTER_INSPECTLET_ID: '1695370721',
  REACT_APP_SECURITY_PERMISSIONS_INSPECTLET_ID: '8157004',
  REACT_APP_FEEDBACK_INSPECTLET_ID: '403843740',
  REACT_APP_ONEONONE_INSPECTLET_ID: '523746530',
  REACT_APP_LMS_INSPECTLET_ID: '347712034',
  REACT_APP_EH_PAYROLL_GUIDE_INSPECTLET_ID: '76258885',
  REACT_APP_EH_ATS_INSPECTLET_ID: '729202490',
  REACT_APP_DEMO_ACCOUNT_FLOW_INSPECTLET: '1785625059',
  REACT_APP_STARTING_GUIDE_PAGE_INSPECTLET: '309894608',
  REACT_APP_URL_SURVEY_SERVICE_API_HOST: 'https://api.ehrocks.com/survey-api',
  REACT_APP_SENTRY_CONFIG:
    'https://7790b73879e54729a0a5ea813a8914ff@sentry.io/210777',
  REACT_APP_AUDIT_API_HOST: 'https://api.ehrocks.com/audit-api/api/v2',
  REACT_APP_LEGACY_AUDIT_API_HOST: 'https://api.ehrocks.com/audit-api/api/v1',
  REACT_APP_GA_UUID: 'UA-33933366-1',
  REACT_APP_TOTANGO_SERVICE_ID: 'SP-25484-01',
  REACT_APP_PAY_SUMMARY_API_HOST: 'https://payment-summary-service.ehrocks.com',
  REACT_APP_MOCK_FOR_CUCUMBER: 'false',
  REACT_APP_ANNOUNCEMENT_API_HOST:
    'https://api.ehrocks.com/announcement-api-production',
  REACT_APP_NOMINATION_SERVICE_API_HOST: 'https://nominations.ehrocks.com',
  REACT_APP_EMPLOYEE_MILESTONE_SERVICE_API_HOST:
    'https://employee-milestones.ehrocks.com',
  REACT_APP_DEPLOY_SANDBOX_ORG_FEATURE_DATE:
    'Tue Jul 17 2018 13:00:00 GMT+0000 (UTC)',
  REACT_APP_HERODOLLAR_PURCHASE_HOST:
    'https://api.ehrocks.com/herodollar-purchase-service',
  REACT_APP_SUPERFUND_LOOKUP_SERVICE_API_HOST:
    'https://api.ehrocks.com/superfund-production',
  REACT_APP_MOBILE_APP_DYNAMIC_LINK: 'https://efq56.app.goo.gl/6xTY',
  REACT_APP_EXTERNAL_AUTH_API_HOST: 'https://external-auth.ehrocks.com',
  REACT_APP_LMS_HOST: 'https://lms.ehrocks.com',
  REACT_APP_ETFND_API_HOST: 'https://etfnd.ehrocks.com/',
  REACT_APP_MAIL_SERVICE_API_HOST: '//mail-service.ehrocks.com/api',
  REACT_APP_SURVEY_HOST: '//api.ehrocks.com/survey-api/api',
  REACT_APP_LMS_PORTAL_NAME_SUFFIX: '.lms.employmenthero.com',
  REACT_APP_FILE_SERVICE_API_HOST: '//files.ehrocks.com',
  REACT_APP_COBRANDING_API_HOST: '//co-branding.ehrocks.com',
  REACT_APP_MEETING_MANAGEMENT_SERVICE_API_HOST:
    'https://meeting-management.ehrocks.com',
  REACT_APP_COMMENT_SERVICE_API_HOST: 'https://comment.ehrocks.com',
  REACT_APP_PERFORMANCE_EVALUATION_SERVICE_API_HOST:
    'https://performance-evaluation.ehrocks.com',
  REACT_APP_ACCOUNT_VERIFICATION_SERVICE_API_HOST:
    'https://account-verification.ehrocks.com',
  REACT_APP_ATS_SERVICE_API_HOST: 'https://services.employmenthero.com/ats',
  REACT_APP_CAREER_PAGE_HOST: 'https://jobs.swagapp.com',
  REACT_APP_BILLING_IMPLEMENTATION_ACCES: 'false',
  REACT_APP_SIDEBAR_CACHING_WHITELIST: '8701,10735,6776,15724',
  REACT_APP_ROSTERING_API_HOST: 'https://rostering.ehrocks.com',
  REACT_APP_CES_SURVEY_DELIGHTED_SURVEY_KEY: 'pRUBfPHzqOOlDaTr',
  REACT_APP_CES_SURVEY_DELIGHTED_SURVEY_LOCALE: 'en-x-employ-9',
  REACT_APP_TAX_STSL_TRANSITION_ENABLED: 'false',
  REACT_APP_PAY_RUN_SERVICE_API_HOST:
    'https://api.employmenthero.com/pay-run-processing',
  REACT_APP_ONBOARD_REMOTE_FIELD_ENABLED: 'false',
  REACT_APP_EXPENSE_API_HOST: 'https://expenses.ehrocks.com',
  REACT_APP_PAYROLL_REPORT_SERVICE_API_HOST:
    'https://api.employmenthero.com/payroll-report',
  REACT_APP_PAYROLL_API_HOST: 'https://payroll-uk.secure.employmenthero.com',
  REACT_APP_ENABLED_APPCUES_SETUP_DASHBOARD: 'true',
  REACT_APP_ENABLED_APPCUES_STANDARD_DASHBOARD: 'true',
  REACT_APP_ENABLED_APPCUES_PREMIUM_DASHBOARD: 'true',
  REACT_APP_ENABLED_APPCUES_PLATINUM_DASHBOARD: 'true',
  REACT_APP_ENABLED_REBRANDING: 'false',
  REACT_APP_LOCALE_CONFIG_HOST: 'https://translation-distribution.ehrocks.com',
  REACT_APP_LOCALE_PROJECT_ID: '6792851960ee448d143ca7.75939114',
  REACT_APP_DISABLED_EMPLOYING_ENTITY_FOR_PAYROLL_FREEMIUM: 'true',
  REACT_APP_INTERNAL_INTEGRATIONS_HOST:
    'https://api.employmenthero.com/integrations',
  REACT_APP_TWOFA_MANDATED: 'true',
  REACT_APP_FORM_HOST: 'https://form.ehrocks.com',
  REACT_APP_EMPLOYMENT_SCHEDULES_API_HOST:
    'https://employment-schedules.ehrocks.com',
  REACT_APP_CONSENT_MARKETING_HOST: 'https://consent-service.ehrocks.com',
  REACT_APP_REACTIONS_HOST: 'https://reactions.ehrocks.com/api',
  REACT_APP_EBF_BFF_MOBILE_HOST:
    'https://ebf-bff-mobile.ehfintech.com/secure/query',
  REACT_APP_EBF_EH_FINTECH_HOST: 'https://api.ehfintech.com',
  REACT_APP_REPORT_SERVICE_API_HOST: 'https://reports.ehrocks.com',
  REACT_APP_FIREBASE_APIKEY: 'AIzaSyDw86jZ_m89pfqFWeDYI9itKkMLUrbhDlY',
  REACT_APP_FIREBASE_AUTH_DOMAIN: 'atsmobile-production.firebaseapp.com',
  REACT_APP_FIREBASE_PROJECT_ID: 'atsmobile-production',
  REACT_APP_FIREBASE_STORAGE_BUCKET: 'atsmobile-production.appspot.com',
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '420574677486',
  REACT_APP_FIREBASE_APP_ID: '1:420574677486:web:1223f26d53d702fee966ff',
  REACT_APP_ENABLED_ATS_CREATE_JOB_NEW_UI: 'true',
  REACT_APP_GOOGLE_API_KEY: 'AIzaSyAFONjoIAeukBOsaIzaWpfxtf1xsW83lfw',
  REACT_APP_GOOGLE_API_KEY_FOR_EMPLOYEE_ADDRESS_PICKER:
    'AIzaSyALf6GbJ7Vot8ohjIccT9BPvu9d3J3zbAM',
  REACT_APP_STRIPE_PUBLIC_KEY_AU:
    'pk_live_51Mxiv3LvmZxRDXTxC8m2LYvVBAJtNzLWtTADUoR7Li3w2nBBh72xFY5qzKrmVoIJvavNqI61mADcQhpprDXivDI30095VndXMq',
  REACT_APP_STRIPE_PUBLIC_KEY_GB:
    'pk_live_51NN3G7GOk6eRCqhcRG7pj0nIG4uSbTJNcE3E1K8yv4yWdcRIQWJDOQxGM59ZA444dhiBZBuSwYis8n4iJhnOSoKL00wCvqWxeI',
  REACT_APP_JOB_LISTING_SUSHI_TRAIN_ENABLED: 'true',
  REACT_APP_SECURE_SAP_OUTBOUND_ORIGIN: 'https://secure.employmenthero.com',
  REACT_APP_SECURE_SAP_INBOUND_ORIGIN: 'https://admin.employmenthero.com',
  REACT_APP_WORKFLOW_WIZARD_API_HOST: 'https://workflow-wizard.ehrocks.com',
  REACT_APP_WORKFLOW_ENGINE_API_HOST: 'https://workflow-engine.ehrocks.com',
  REACT_APP_ACCOUNT_PROVISIONING_API_HOST:
    'https://account-provisioning.ehrocks.com',
  REACT_APP_RECAPTCHA_SITE_KEY: '6LchtXcqAAAAACjrO6oga6o-fdEE2Tug_yoBlK9r',
  REACT_APP_RECAPTCHA_V2_SITE_KEY: '6Lebrc0ZAAAAAIaiAtRgPLQw5-mhx4ANbFis-3n4',
  REACT_APP_CHECKBOX_RECAPTCHA_SITE_KEY:
    '6LeaFYkqAAAAAOlm3DQFqWXAh1lbnl7zOpNiElzl',
  REACT_APP_FILEPICKER_API_KEY: 'AyDAU3JxgR3aOIFMnReNsz',
  REACT_APP_VITALLY_TOKEN:
    'ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmhkV1FpT2lJMk5URXpNekF4TXkwNVpXWTFMVFE0WVdVdE9EVTJaaTB4TldOaFl6QmpORFkwWVdZaUxDSmhjR2tpT2lKaVlXVTJPVFF3TWkwNE5HTTRMVFJoTXpNdFlUYzBZUzFqWVdSaFkyTTFORGcxWkRVaUxDSnpaV01pT2lJek0yTTBabUkwTVNKOS5zNHd5enBwd0pPaW00R3M3SWpubS1nVDhubkF2UkM4RGhQMFJkcjZXajJvOg==',
  REACT_APP_ENABLE_SMART_MATCH_MARK_AS_SHOWN: 'true',
  REACT_APP_PAYROLL_AU_PRIVATE_API_HOST:
    'https://employmenthero.yourpayroll.com.au',
  REACT_APP_PAYROLL_NZ_PRIVATE_API_HOST:
    'https://employmenthero.nzpayroll.co.nz',
  REACT_APP_PAYROLL_UK_PRIVATE_API_HOST:
    'https://employmenthero.yourpayroll.co.uk',
  REACT_APP_PAYROLL_MY_PRIVATE_API_HOST:
    'https://employmentheromy.yourpayroll.io',
  REACT_APP_PAYROLL_SG_PRIVATE_API_HOST:
    'https://employmenthero.yourpayroll.io',
  REACT_APP_PAYROLL_AU_PUBLIC_API_HOST:
    'https://payroll.secure.employmenthero.com',
  REACT_APP_PAYROLL_NZ_PUBLIC_API_HOST:
    'https://payroll.secure.employmenthero.com',
  REACT_APP_PAYROLL_UK_PUBLIC_API_HOST:
    'https://payroll-uk.secure.employmenthero.com',
  REACT_APP_PAYROLL_MY_PUBLIC_API_HOST:
    'https://payroll.secure.employmenthero.com',
  REACT_APP_PAYROLL_SG_PUBLIC_API_HOST:
    'https://payroll.secure.employmenthero.com',
  REACT_APP_BILLING_IMPLEMENTATION_ACCESS: '',
  REACT_APP_CHATBOT_FEEDBACK_ENABLED: '',
  REACT_APP_CHATBOT_SIDEPANEL_ENABLED: '',
  REACT_APP_DIGITAL_SIGNATURE_WITH_SIGN_UP: '',
  REACT_APP_E2P0_MAILCHIMP_API_URL: '',
  REACT_APP_E2P0_MAILCHIMP_LIST_ID: '',
  REACT_APP_ENABLED_CONNECT_CANDIDATE_GUIDE: '',
  REACT_APP_ENABLED_FF_CUSTOM_ATTRIBUTES: '',
  REACT_APP_ENABLED_GT_INTERNAL_NEW_HIRE: '',
  REACT_APP_ENABLED_KP_THEME: '',
  REACT_APP_ENABLED_LMS_FEATURES: '',
  REACT_APP_ENABLED_ONE_ON_ONE_FEATURES: '',
  REACT_APP_INTERNAL_support01S_HOST: '',
  REACT_APP_KEYPAY: '',
  REACT_APP_LEARNING_REFRESH_TOKEN_REVOKED_NOTICE_ENABLED: '',
  REACT_APP_MANUALLY_TRIGGER_ONBOARDING_CHECKLISTS_ENABLED: '',
  REACT_APP_MIXPANEL_TOKEN: '',
  REACT_APP_MOCK_DATA: '',
  REACT_APP_PEO_ACCESS: '',
  REACT_APP_S3: '',
  REACT_APP_SECURITY_GROUPS_MIGRATION_ENABLED: '',
  REACT_APP_SHOW_CUSTOM_SURVEY_WIDGET: '',
  REACT_APP_SURVEYS_WITH_ALLOCATIONS: '',
  REACT_APP_SURVEYS_WITH_START_DATE: '',
  REACT_APP_TURN_OFF_INTENTION: '',
  REACT_APP_USE_NEW_SETUP_TWOFA_MODAL_UI: '',
  REACT_APP_ZENDESK_WIDGET_KEY: 'c2791210-60c1-4c9c-a1a8-d2940fe9a008',
  REACT_APP_ZENDESK_WIDGET_SETUP_MODE_KEY:
    '2b025e94-9ed0-440e-91e2-6c3573c6b5bd',
  REACT_APP_AWARE_ENCRYPTED_KEY_ID: 'employmenthero',
  REACT_APP_AWARE_PASSWORD_PUBLIC_KEY:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtwVMu9LSdJHbjxVJB0qSHOlY8ha5T7JcYn1IYwo3fmpRhgHyn9ima3INh/y13Y/9bbQ2HYx9Q53HFEtHsFQ3uJC8UbCMNnv0IccDLat34cXTtYSfN8Momzy07SueQm2ELIWaR/yMtU2pncMmXRCSYAdRhCgj/+f5uXvYa4Um2OOc6ktNjrprrpo6s3K/SzCpdz/H/0bBLw6AWTDyIIIK6nrXngdSWAwp1kuf6KMLcDWOhfA+z8x7oSm+xB+06lddWBsVGIUFXlBJpJ3m3oaE9UAVco5Q3V2HPG3w/kk9FgAptnITftK3dgKKDyNpjtTqBxw7f5vmLm40cgmJDqH3XQIDAQAB',
  REACT_APP_BRAZE_API_KEY: '04c7a372-e7db-411e-bf55-e5b8ae372841',
  REACT_APP_LIFECYCLE_SERVICE_HOST: 'https://lifecycle-service.ehrocks.com',
  REACT_APP_ZENDESK_AI_SESSION_FIELD_ID: '10354549573903',
  REACT_APP_SALARY_GUIDE_SERVICE_HOST: 'https://salary-guide.ehrocks.com',
  REACT_APP_SM_HUB_OCCUPATIONS_LIST_HOST:
    'https://addons-assets.employmenthero.com/smartmatch-hub/occupations.json',
  REACT_APP_SM_HUB_LOCATIONS_LIST_HOST:
    'https://addons-assets.employmenthero.com/smartmatch-hub/location.json',
  REACT_APP_ENABLED_DATADOG_RUM: 'true',
  REACT_APP_DATADOG_RUM_APP_ID: 'de28524c-7dab-472e-bcae-48a0b597e58d',
  REACT_APP_DATADOG_PUBLIC_TOKEN: 'pubdf2ada7f87e02e1f1529821abc5c2381',
  REACT_APP_ZENDESK_WIDGET_ONBOARDING_KEY:
    'cc3e67f8-d97f-488e-b3dc-50c99ec5499e',
  REACT_APP_ZENDESK_WIDGET_SLICE_2_KEY: 'b9fc685b-4ef6-45ef-8df2-a334b846fdeb',
  REACT_APP_ZENDESK_WIDGET_SUPPORTED_COUNTRY_SETUP_MODE_KEY:
    '2b025e94-9ed0-440e-91e2-6c3573c6b5bd',
  REACT_APP_ZENDESK_WIDGET_DEFAULT_KEY: 'c2791210-60c1-4c9c-a1a8-d2940fe9a008',
  REACT_APP_ZENDESK_WIDGET_SIGN_IN_SUPPORT_KEY:
    '1cf2a1e5-5407-45b1-a8e5-6ddaecdc62a1',
  REACT_APP_IMPERSONATION_CONSUME_URL:
    'https://secure.support01.employmenthero.com/users/consume_impersonation',
};

export default ENV;
