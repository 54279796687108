// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { init, globalHandlersIntegration } from '@sentry/browser';
import get from 'lodash/fp/get';
import some from 'lodash/fp/some';
import truncate from 'lodash/fp/truncate';

import { getNodeEnv, getSentryConfig } from './getFrontendEnv';
import { getRailsEnv } from './railsEnvironment';

const denyUrls = [
  // Application custom
  // CKEditor
  /ckeditor.js$/,
  /\/standard-all\/plugins\/tabletools\/dialogs\/tableCell.js/,

  // Setry recommendation
  // Browser extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^[a-z]+-extension:\/\//i,

  /ao-injector.remote.js$/,

  // Tracking script
  /inspectlet.js/,
  /libs\/mixpanel/,

  // User machine
  /\/[A-Z]:\//,

  // One trust
  /otBannerSdk.js$/,

  // GTM tracking
  /gtag\/js$/,
  /gtm.js$/,

  // Adsrvr
  /up_loader\.(.*)\.js$/,
];

const ignoreErrors = [
  // Incomplete loading JS from mainapp
  /'?safeOnLoad'? is not defined/,
  /'?safeOnLoad'? is undefined/,
  /Can't find variable: '?safeOnLoad'?/,

  // Known issue on chrome iOS when opening filepicker modal
  /dialog.filepicker.io/,
  // Error from twitter widget script
  /Cannot read property 'twttr.button' of null/,

  // Known issue on chrome iOS
  /Blocked a frame with origin .* from accessing a cross-origin frame. Protocols, domains, and ports must match/,
  // Known error on Samsung device
  /document.getElementsByClassName.ToString is not a function/,
  // Issue from IE
  /Operation aborted/,
  // Network error when loading webpack chunk
  /Loading chunk [0-9]+ failed./,
  // User cancel action
  /The operation was canceled by the user./,

  // client custom error
  /myGloFrameList/,
  // https://stackoverflow.com/questions/50272371/what-is-betterjspop-error-on-chrome
  /BetterJsPop/,
  // simply a not supported error
  /NotSupportedError/,
  /vid_mate_check is not defined/,

  // Firefox < 42 error https://bugzilla.mozilla.org/show_bug.cgi?id=866372
  /internal error while computing Intl data/,
  /Error calling method on NPObject!/,

  // Intendedly duplicated reducer branch
  'Reducer [okrs/listOkrsPage] is already REGISTERED',

  // Ignore irrelevant error
  /InvalidStateError: The object is in an invalid state/,
  /InvalidStateError: InvalidStateError/,

  // Ignore noise caused by 3rd party extension: Grammarly
  /ResizeObserver loop limit exceeded/,
  // Ignore safely, this's like framedrop, no affect
  /ResizeObserver loop completed with undelivered notifications\./,

  // Network error / Offline from client side, we can't handle them
  /Failed to fetch/,
  /NetworkError when attempting to fetch resource\./,
  /The internet connection appears to be offline\./,
  /Network request failed/,
  /Could not connect to the server\./,
  /Non-Error promise rejection captured\./,

  // Ignore noise caused by Edge Mobile's Bing Chat
  /Can't find variable: msDiscoverChatAvailable/,

  // Ignore common missing feature flag errors when rolling out a new feature
  /\[4[0-9][0-9]\] Request failed: checkFeatureFlag\/.*/,
];

const ignoreUserAgents = [
  /MQQBrowser/,
  /MSIE/, // IE 9 and below
  /SamsungBrowser/,
  /YaBrowser/,
];

const getSampleRate = () =>
  getRailsEnv() === 'development' || getNodeEnv() === 'development' ? 0.0 : 1.0;

const getSentryEnv = () => {
  const hostName = window.location.hostname;

  if (hostName === 'secure.employmenthero.com') {
    return 'production';
  }

  if (hostName === 'secure.support01.employmenthero.com') {
    return 'support01';
  }

  if (hostName === 'secure.integration.employmenthero.com') {
    return 'integration';
  }

  if (hostName.includes('staging.ehrocks')) {
    return 'sandbox';
  }

  return 'unknown';
};

const getReactAppSentryReleaseVersion = () =>
  window.gon?.frontend_version || 'unknown';

type InitSentryOptions = {
  fromKeyPay: boolean;
  integrations: {
    enabledTryCatch: boolean;
    enabledOnUnhandledRejectionHandler: boolean;
    enabledOnErrorHandler: boolean;
  };
};

const initSentry = (
  userEmail: string | undefined | null,
  options?: InitSentryOptions = {
    fromKeyPay: false,
    integrations: {
      enabledTryCatch: true,
      enabledOnUnhandledRejectionHandler: false,
      enabledOnErrorHandler: true,
    },
  }
) => {
  init({
    dsn: getSentryConfig(),
    environment: getSentryEnv(),
    release: getReactAppSentryReleaseVersion() || 'unknown',
    denyUrls,
    ignoreErrors,
    shutdownTimeout: 100,
    sampleRate: getSampleRate(),
    initialScope: scope => {
      scope.setLevel('error');
      scope.setUser({ email: userEmail });

      scope.setTag('from', options?.fromKeyPay === true ? 'KP' : 'EH');
      scope.setTag('node_version', '20');
      scope.setTag('sentry_sdk_version', 'v7');

      return scope;
    },
    integrations: integrations =>
      [
        ...integrations,
        globalHandlersIntegration({
          onunhandledrejection: options.enabledOnUnhandledRejectionHandler,
          onerror: options.enabledOnErrorHandler,
        }),
      ].filter(integration => {
        if (integration.name === 'TryCatch') {
          return options.enabledTryCatch === true;
        }
        return true;
      }),
    beforeBreadcrumb: (breadcrumb, hint) => {
      const IGNORED_URLS = [
        'api.mixpanel.com',
        'api-js.mixpanel.com',
        'track.ehrocks.com',
      ];

      const isHttpType = breadcrumb.type === 'http';
      const url = breadcrumb?.data?.url;
      if (
        isHttpType &&
        some(ignoredUrl => url?.includes(ignoredUrl))(IGNORED_URLS)
      ) {
        return null;
      }

      // Filter console logs data since they do not give any useful info
      if (breadcrumb.category === 'console') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, ...breadcrumbWithoutData } = breadcrumb;
        return breadcrumbWithoutData;
      }

      if (breadcrumb.category === 'ui.click') {
        const label = hint?.event.target.innerText;
        if (label) {
          return {
            ...breadcrumb,
            message: `${breadcrumb.message}[label="${truncate({
              length: 20,
            })(label)}"]`,
          };
        }
      }

      return breadcrumb;
    },
    beforeSend: event =>
      some((userAgent: RegExp) =>
        userAgent.test(get('request.headers["User-Agent"]')(event))
      )(ignoreUserAgents)
        ? null
        : event,
  });
};

export default initSentry;
