import startsWith from 'lodash/fp/startsWith';
import split from 'lodash/fp/split';
import take from 'lodash/fp/take';
import flow from 'lodash/fp/flow';
import join from 'lodash/fp/join';
import set from 'lodash/fp/set';
import drop from 'lodash/fp/drop';
import replace from 'lodash/fp/replace';
import last from 'lodash/fp/last';
import dropRight from 'lodash/fp/dropRight';

const removeLastPathElement = () => {
  const pathName = window.location.pathname;
  return flow(split('/'), dropRight(1), join('/'))(pathName);
};

export const getPreviewUrl = () => removeLastPathElement();

export const previewRedirectUrl = () => removeLastPathElement();

export const getSpaPathFromLocation = () => {
  const pathName = window.location.pathname;
  if (startsWith('/admin')(pathName)) {
    return '/admin/spa';
  }

  return flow(split('/'), take(5), join('/'))(pathName);
};

export const getHeroshopSupplierPortalPathFromLocation = () =>
  '/app/v2/heroshop_supplier_portal';

export const getUserSpaApiPathFromLocation = ({
  omitOrgId,
}: { omitOrgId?: boolean } = {}) => {
  const urlLength = omitOrgId ? 3 : 5;
  const pathName = window.location.pathname;

  return flow(
    split('/'),
    take(urlLength),
    set('[1]', 'api'),
    join('/')
  )(pathName);
};

export const getB2bSupplierPortalPathFromLocation = () =>
  '/app/v2/b2b_supplier_portal';

export const removeSpaPath = (pathName: string) => {
  const name = flow(split('/'), drop(5), join('/'))(pathName);
  return `/${name}`;
};

export const isTemplatePage = () => {
  const pathName = window.location.pathname;
  const pagePathWithoutSpa = removeSpaPath(pathName);
  return startsWith('/templates')(pagePathWithoutSpa);
};

// It's a fix for contract api host
// aka a fix for getUserSpaApiPathFromLocation
// Because we use env to store path of api endpoint
// Therefore, it's hard to update version of just 1 api
// Author: Khoa Thai

export const updateUserContractApiVersion = (newVersion = 'v2') =>
  replace('api/v2', `api/${newVersion}`);

export const extractScopeIdFromPath = (scope: string) => {
  const regex = new RegExp(`${scope}/([0-9]+)`);
  const path = window.location.pathname;
  return path && last(path.match(regex));
};

export const extractOrganisationIdFromPath = () => {
  const id = extractScopeIdFromPath('organisations');
  return id ? parseInt(id, 10) : undefined;
};

export const extractMemberIdFromPath = () => {
  const id = extractScopeIdFromPath('members');
  return id && parseInt(id, 10);
};
