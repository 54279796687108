/* eslint-disable immutable/no-mutation */
export const preloadIcon = () => {
  try {
    const icon = document.createElement('div');
    icon.style.width = '0px';
    icon.style.height = '0px';
    icon.style.visibility = 'hidden';
    icon.style.overflow = 'hidden';
    icon.classList.add('hero-icon-user');
    document.body.appendChild(icon);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
