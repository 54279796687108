import get from 'lodash/fp/get';
import isNil from 'lodash/fp/isNil';
import getOr from 'lodash/fp/getOr';

const isRailsTestEnv = window.gon && window.gon.env === 'test';
const isRailsStagingEnv = window.gon && window.gon.env === 'staging';
const isRailsProductionEnv = window.gon && window.gon.env === 'production';

const getRailsEnv = () => {
  const env = get('gon.env')(window);
  if (!isNil(env)) {
    return env;
  }
  return 'development';
};

const smallOrgMaxNumberOfEmployees =
  window.gon && window.gon.small_org_max_number_of_employees;

const EH_ABN = getOr('', 'gon.eh_abn', window);

export {
  isRailsTestEnv,
  isRailsStagingEnv,
  isRailsProductionEnv,
  getRailsEnv,
  smallOrgMaxNumberOfEmployees,
  EH_ABN,
};
