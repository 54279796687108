import { RuntimeEnvironmentVariable } from './env';
import sbxFallback from './sbx-fallback';

const ENV: RuntimeEnvironmentVariable = {
  ...sbxFallback,

  REACT_APP_ACCOUNT_PROVISIONING_API_HOST: '',
  REACT_APP_EBF_BFF_MOBILE_HOST:
    'https://ebf-bff-mobile.staging-ehfintech.com/secure/query',
  REACT_APP_EBF_EH_FINTECH_HOST: 'https://api.staging-ehfintech.com',
  REACT_APP_JOB_LISTING_SUSHI_TRAIN_ENABLED: 'true',
  REACT_APP_KP_SYNC_SERVICE_HOST: 'https://sbx-kss-mobile.staging.ehrocks.com',
  REACT_APP_MARKETPLACE_HOST:
    'https://sbx-mobile-marketplace.staging.ehrocks.com',
  REACT_APP_MEETING_MANAGEMENT_SERVICE_API_HOST:
    'https://meeting-management.staging.ehrocks.com',
  REACT_APP_PAYROLL_API_HOST: 'https://payroll.mobile.staging.ehrocks.com',
  REACT_APP_REACTIONS_HOST: '',
  REACT_APP_RECAPTCHA_V2_SITE_KEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  REACT_APP_CHECKBOX_RECAPTCHA_SITE_KEY:
    '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
  REACT_APP_STARTING_GUIDE_PAGE_INSPECTLET: '',
  REACT_APP_STRIPE_PUBLIC_KEY_AU:
    'pk_test_51Mxiv3LvmZxRDXTxrsJaDZCWGLy7D8geXvV3WY0TblMKKe60z29LkeU99FJ38bh35yMT4Okkq5a8fl4icb1scgnU00BnQOciEA',
  REACT_APP_STRIPE_PUBLIC_KEY_GB:
    'pk_test_51NN3G7GOk6eRCqhcIiXpemqcTBNjdppkfUo5kcbpzHX6ug5WICSsRpNpOfQJU6NEJnwaz5cHueLkojIkds57H9Ob00OvoVCI39',
  REACT_APP_SUPERFUND_LOOKUP_SERVICE_API_HOST:
    'https://api.staging.ehrocks.com/superfund',
  REACT_APP_SURVEY_HOST: '//api.staging.ehrocks.com/survey-api/api',
  REACT_APP_SURVEYS_WITH_ALLOCATIONS: 'true',
  REACT_APP_VITALLY_TOKEN: '',
  REACT_APP_WORKFLOW_WIZARD_API_HOST: '',
  REACT_APP_BRAZE_API_KEY: 'f881f592-91c4-4efb-8d6e-1ebf4eae091c',
  REACT_APP_CONSENT_MARKETING_HOST:
    'https://consent-service.staging.ehrocks.com',
};

export default ENV;
