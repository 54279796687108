import React, { ReactNode, createContext, useContext } from 'react';

import { Configs, globalEnvsRegistry } from './registry';

type GlobalEnvConfigsProps = {
  configs: {
    [key in keyof Configs]: Configs[key];
  };
};

const GlobalEnvConfigsContext = createContext<GlobalEnvConfigsProps>({
  configs: {},
});

/**
 * `GlobalEnvConfigsProvider` provides the global registered configs to child
 * components via context.
 *
 * @example
 *   ```
 *   import { GlobalEnvConfigsProvider } from '@ehrocks/envs-registry';
 *
 *   const App = () => {
 *     return (
 *       <GlobalEnvConfigsProvider>
 *         <Example />
 *       </GlobalEnvConfigsProvider>
 *     );
 *   }
 *   ```;
 *
 * @property {ReactNode} children - The child components that have access to the
 *   global configs.
 */
const GlobalEnvConfigsProvider = ({ children }: { children: ReactNode }) => {
  const configs = globalEnvsRegistry.registeredConfigs;
  return (
    <GlobalEnvConfigsContext.Provider value={{ configs }}>
      {children}
    </GlobalEnvConfigsContext.Provider>
  );
};

/**
 * `useGlobalConfigsProvider` is a custom hook helps to access the global
 * registered configs.
 *
 * @example
 *   ```
 *   import { useGlobalConfigsProvider } from '@ehrocks/envs-registry';
 *
 *   const Example = () => {
 *     const configs = useGlobalEnvConfigs();
 *
 *     return (
 *       <div>Config value: {configs.someConfigValue}</div>
 *     );
 *   }
 *   ```;
 */
const useGlobalEnvConfigs = () => {
  const { configs } = useContext(GlobalEnvConfigsContext);
  return configs;
};

export { GlobalEnvConfigsProvider, useGlobalEnvConfigs };
