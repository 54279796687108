import { EnvironmentVariable } from './env';
import { frontendEnv } from './frontendEnv';

export const frontendTestEnv = { ...frontendEnv };

export const updateEnv = (name: keyof EnvironmentVariable, value: string) => {
  // eslint-disable-next-line immutable/no-mutation
  frontendTestEnv[name] = value;
};

export const bulkUpdateEnv = (env: Partial<EnvironmentVariable>) => {
  Object.entries(env).forEach(([key, value]) => {
    updateEnv(key as keyof EnvironmentVariable, value);
  });
};
